import * as React from "react";
import { graphql } from "gatsby";
import PageContent from "../../../components/layout/content";
import Layout from "../../../components/layout/layout";
import { useMemo } from "react";
import useLayoutGeneration from "../../../core/hooks/useLayoutGeneration";
import "../../../theme/style.scss";
import Seo from "../../../components/basics/seo/seo";
import AnimatedHeader from "../../../components/layout/header/animatedHeader/animatedHeader";

const GuidePage = ({ data }) => {
  const pageData = useMemo(() => data.contentfulOldal, [data]);
  const contents = useMemo(
    () => pageData?.tartalomTpus?.tartalom || [],
    [pageData]
  );

  const [generator, resolver] = useLayoutGeneration();

  const generatedContent = useMemo(() => {
    const generatedList = generator(contents);
    const componentList = generatedList.map(resolver);
    return componentList;
  }, [contents, generator, resolver]);

  return (
    <Layout>
      <Seo
        title={pageData.cm}
        description={pageData.alcm}
        url={
          pageData?.kategria?.slug
            ? `${pageData?.kategria?.slug}/${pageData.slug}`
            : pageData.slug
        }
      />
      <AnimatedHeader queryData={pageData?.tartalomTpus?.header} />
      <PageContent>{generatedContent}</PageContent>
    </Layout>
  );
};
//hozzá kell adni az összes építőblock típust
export const query = graphql`
  query general($contentfulId: String) {
    contentfulOldal(contentful_id: { eq: $contentfulId }) {
      slug
      cm
      alcm
      kategria {
        slug
      }
      tartalomTpus {
        ... on ContentfulGuidesTartalomTipus {
          id
          header {
            lers
            title
          }
          tartalom {
            ... on ContentfulSzekcio {
              token: contentful_id
              szekcicm {
                raw
              }
              ComponentContent: tartalom {
                ... on ContentfulFaq {
                  id
                  questionBlock: krdsek {
                    cm
                    lers
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulCtaBox {
                  cm
                  szveg
                  ctaGomb {
                    ... on ContentfulCtaGomb {
                      ctaGombLink {
                        ... on ContentfulOldal {
                          slug
                          kategria {
                            slug
                          }
                        }
                        ... on ContentfulOldalKategoria {
                          slug
                        }
                      }
                      ctaGombSzveg
                      externalCtaGombLink
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulYoutubeVideoBeagyazas {
                  id
                  internal {
                    type
                  }
                  contentfulCm
                  transparentImage
                  bortkp {
                    contentful_id
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                  yotubeVideoUrl
                }
                ... on Contentful2OszloposSor {
                  id
                  alcm
                  cm
                  kp {
                    contentful_id
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                  nagythatKp
                  internal {
                    type
                  }
                  oszlopTartalom: tartalom {
                    raw
                  }
                  ctaGomb {
                    ... on ContentfulCtaGomb {
                      ctaGombLink {
                        ... on ContentfulOldal {
                          slug
                          kategria {
                            slug
                          }
                        }
                        ... on ContentfulOldalKategoria {
                          slug
                        }
                      }
                      ctaGombSzveg
                      externalCtaGombLink
                    }
                  }
                }
              }
              themeColoured
              internal {
                type
              }
            }
          }
        }
      }
    }
  }
`;

export default GuidePage;
